
.testimonials-container.container{
    width: 40%;
    padding-bottom: 4rem;
}
.client-avatar {
    width: 4rem;
    aspect-ratio: 1/1;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto 1rem;
}
#dark .client-avatar {

    border: 0.15rem solid var(--primary-dark);

}
#light .client-avatar {

    border: 0.15rem solid var(--primary-light);

}
.testimonials {
    background: var(--color-bg-variant);
    text-align: center;
    padding: 2rem;
    border-radius: 2rem;
    user-select: none;
}
#dark .testimonials {
    background: var(--dark-variant);
}
#light .testimonials {
    background: var(--light-variant);
}
.client-review {
    
    font-weight: 300;
    width: 80%;
    margin: 0.8rem auto 0;
}
#dark .client-review {
    color: var(--text-dark-content);
}
#light .client-review {
    color: var(--text-light-content);
}
#dark .swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--variant-dark);
}
#light .swiper-pagination-clickable .swiper-pagination-bullet {
    background: var(--variant-light);
}
/* media queries medium devices */ 

@media screen and (max-width: 1024px) {
    .container.testimonials-container {
        width: 60%;
    }
}


/* media queries small devices */ 

@media screen and (max-width: 600px) {
    .container.testimonials-container {
        width: var(--container-width-sm);
    }
    .client-review {
        width: var(--container-width-sm);
    }
}