.container-services {
    /* display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 3rem; */
    width: 30rem;
}

.service {
   
    border-radius: 0 0 2rem 2rem;
    border: 1px solid var(--color-primary);
    height: fit-content;
    transition: var(--transition);
}
#dark .service {
    background: var(--dark-variant);
    border: 1px solid var(--variant-dark);
}
#light .service {
    background: var(--light-variant);
    border: 1px solid var(--variant-light);
}

#dark .service:hover {
    background: transparent;
    border-color: var(--primary-dark);
    cursor: default;
}
#light .service:hover {
    background: transparent;
    border-color: var(--primary-light);
    cursor: default;
}

.service-head {
    
    padding: 2rem;
    border-radius: 0 0 2rem 2rem;
    box-shadow: 0 2rem 1rem rgba(0, 0, 0, 0.1);
}
#dark .service-head {
    background: var(--variant-dark);
}
#light .service-head {
    background: var(--variant-light);
}
.service-head h3 {
    
    font-size: 1rem;
    text-align: center;
}
#dark .service-head h3 {
    color: var(--text-dark-title);
}
#light .service-head h3 {
    color: var(--text-light-title);
}
.service-list {
    padding: 2rem;
}

.service-list li {
    display: flex;
    gap: 1rem;
    margin-bottom: 0.8rem;
}
#dark .service-list li p {
    color: var(--text-dark-content);
}
#light .service-list li p {
    color: var(--text-light-content);
}
#dark .service-list .icon {
    color: var(--primary-dark);
}
#light .service-list .icon {
    color: var(---primary-light);
    width: 2rem;
}
.service-list p {
    font-size: 0.9rem;
    width: 20rem;
}


/* media queries medium devices */ 

@media screen and (max-width: 1024px) {
    .container-services {
        /* grid-template-columns: 1fr 1fr;
        gap: 2rem; */
        width: 30rem;
    }
    .service {
        height: auto;
    }
}


/* media queries small devices */ 

@media screen and (max-width: 600px) {
    .container-services {
        /* grid-template-columns: 1fr;
        gap: 1.5rem; */

        width: 20rem;
    }

}