.experience-container {
    display : grid;
    grid-template-columns: 1fr 1fr;
    gap: 2rem;
    
}

.experience-container > div {
    padding: 2.4rem 5rem;
    border-radius: 2rem;
    border: 1px solid transparent;
    transition: var(--main-transition);
}
#dark .experience-container > div {

    background: var(--dark-variant);
}
#light .experience-container > div {

    background: var(--light-variant);
}

#dark .experience-container > div:hover,
#light .experience-container > div:hover {
    background: transparent;
    cursor: default;
}
#dark .experience-container > div:hover {
    border-color: var(--primary-dark);
}
#light .experience-container > div:hover {
    border-color: var(--primary-light);
}
.experience-container > div h3 {
    text-align: center;
    margin-bottom: 2rem;
    
}
#dark .experience-container > div h3 {
    color: var(--primary-dark);
}
#light .experience-container > div h3 {
    color: var(--primary-light);
}
.experience-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;

}

.experience-details {
    display: flex;
    gap: 1rem;
    justify-content: center;
}

.experience-details .icon {
    margin-top: 6px;
    color: var(--color-primary-variant);
    width: 2rem;
}
#dark .experience-details .icon {

    color: var(--variant-dark);
}
#light .experience-details .icon {

    color: var(--variant-light);
}
#dark .experience-details h4 {
    color: var(--text-dark-title);
}
#light .experience-details h4 {
    color: var(--text-light-title);
    width: 10rem;
}
#dark .experience-details small {
    color: var(--text-dark-content);
    font-size: 0.7rem;
}
#light .experience-details small {
    color: var(--text-light-content);
    font-size: 0.7rem;
}

/* media queries medium devices */ 

@media screen and (max-width: 1024px) {
    .experience-container {
        grid-template-columns: 1fr;
    }
    .experience-container > div {
        width: 80%;
        padding: 2rem;
        margin: 0 auto;
    }
    .experience-content {
        padding: 1rem;
    }
}


/* media queries small devices */ 

@media screen and (max-width: 600px) {
    .experience-container {
        gap: 1rem;
    }
    .experience-container > div {
        width: 100%;
        padding: 2rem 1rem;
    }
}