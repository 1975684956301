.logo {
    width: 150px;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4rem;
    -webkit-filter: grayscale(1) invert(1);
    filter: grayscale(1) invert(1);
}
#dark .logo {
    width: 150px;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;
    padding-top: 4rem;
    -webkit-filter: grayscale(0) invert(0);
    filter: grayscale(0) invert(0);
}
footer {
    
    padding: 3rem 0;
}
#light footer {
    background:var(--light-variant);
}
#dark footer {
    background:var(--dark-variant);
}
#dark footer a {
    color: var(--primary-dark);
}
#light footer a {
    color: var(--primary-light);
}
.permalinks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;
    margin: 0 auto 3rem;
}
.footer-social {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 4rem;
}
#dark .footer-social a {
    background: var(--primary-dark);
    color: var(--text-light-title);
    padding: 0.8rem 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}
#light .footer-social a {
    background: var(--primary-light);
    color: var(--text-light-title);
    padding: 0.8rem 0.8rem;
    border-radius: 0.7rem;
    display: flex;
    border: 1px solid transparent;
}
#dark .footer-social .icon {
    color: var(--text-dark-title);
}
#light .footer-social .icon {
    color: var(--text-light-title);
}
#dark .footer-social a:hover {
    background: transparent;
    color: var(--primary-dark);
    border-color: var(--primary-dark);
}
#light .footer-social a:hover {
    background: transparent;
    color: var(--primary-light);
    border-color: var(--primary-light);
}
#dark .footer-copyright {
    margin-bottom: 4rem;
    text-align: center;
    font-size: 14px;
    font-weight: 300; 
    color: var(--primary-dark);
}
#light .footer-copyright {
    margin-bottom: 4rem;
    text-align: center;
    font-size: 16px;
    font-weight: 300; 
    color: var(--primary-light);
}
/* media queries small devices */ 

@media screen and (max-width: 600px) {
    .permalinks {
        align-items: center;
        flex-direction: column;
        gap: 1.5rem;
    }
    .footer-social {
        margin-bottom: 2.6rem;
    }
}